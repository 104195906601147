import { createReducer } from '@reduxjs/toolkit';

import {
  fetchDirectConfigs,
  setFilters,
  setSortBy,
  clearAll
} from '@infrastructure/store/directConfigList/directConfigListActions';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

const listInitial: IDirectConfig[] = [];

export const listReducer = createReducer(listInitial, (qb) => {
  qb.addCase(fetchDirectConfigs.success, (state, { payload }) => {
    state.push(...payload.thisPageItems);
    return state;
  });
  qb.addCase(setFilters, () => {
    return listInitial;
  });
  qb.addCase(setSortBy, () => {
    return listInitial;
  });
  qb.addCase(clearAll, () => {
    return listInitial;
  });
});
