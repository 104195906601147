import React, { useCallback } from 'react';

import { directConfigListSelectors } from '@infrastructure/store/directConfigList/directConfigListSelectors';
import { setSortBy } from '@infrastructure/store/directConfigList/directConfigListActions';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { SortByEvent } from '@ui/types/shared';
import { TableCellType } from '@components/layout/table/types/TableCellType';
import { SortColumn } from '@components/layout/sortColumn/SortColumn';

type HeaderCellProps = {
  type: string;
  labelText: string;
  fieldName?: keyof IDirectConfig;
  sortBy?: SortByEvent<keyof IDirectConfig>;
  handleSort?: (payload: SortByEvent<keyof IDirectConfig>) => void;
};

function HeaderCell({ type, labelText, fieldName, sortBy, handleSort }: HeaderCellProps) {
  if (fieldName && sortBy && handleSort) {
    return (
      <th data-cell={type}>
        <SortColumn fieldName={fieldName} sortBy={sortBy} handleSort={handleSort}>
          {labelText}
        </SortColumn>
      </th>
    );
  }

  return <th data-cell={type}>{labelText}</th>;
}

export function DirectConfigHeader() {
  const dispatch = useDispatch();
  const sortBy = useSelector(directConfigListSelectors.getSortBy);

  const handleSort = useCallback(
    (payload: SortByEvent<keyof IDirectConfig>) => {
      dispatch(setSortBy(payload));
    },
    [dispatch]
  );

  return (
    <tr>
      <HeaderCell type={TableCellType.TITLE} labelText="App Name" />
      <HeaderCell
        type={TableCellType.TITLE}
        labelText="Test Name"
        fieldName="name"
        handleSort={handleSort}
        sortBy={sortBy}
      />
      <HeaderCell type={TableCellType.ICON} labelText="Store" />
      <HeaderCell type={TableCellType.TEXT} labelText="Test Type" />
      <HeaderCell
        type={TableCellType.LABEL}
        labelText="Status"
        fieldName="state"
        handleSort={handleSort}
        sortBy={sortBy}
      />
      <HeaderCell
        type={TableCellType.DATE}
        labelText="Dates"
        fieldName="stateStartDate"
        sortBy={sortBy}
        handleSort={handleSort}
      />
      <HeaderCell type={TableCellType.LABEL} labelText="Country" />
    </tr>
  );
}
