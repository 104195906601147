import React from 'react';
import { FieldValues } from 'react-hook-form';

import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { FormComponent } from '@ui/hooks/form';
import { ControlledBooleanInput } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/inputs/ControlledBooleanInput';
import { ControlledIntegerInput } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/inputs/ControlledIntegerInput';
import { ControlledIntegerListInput } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/inputs/ControlledIntegerListInput';
import { ControlledTextInput } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/inputs/ControlledTextInput';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  sessionIndex: number;
  variables: ExperimentVariableDto[];
};

export function Session<T extends FieldValues>({ control, sessionIndex, variables }: Props<T>) {
  const inputs = variables.map(({ type, name, displayName, minValue, maxValue }) => {
    switch (type) {
      case ExperimentVariableType.INT_LIST:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerListInput
              control={control}
              name={name}
              index={sessionIndex}
              labelText={displayName}
              min={minValue}
              max={maxValue}
            />
          </div>
        );
      case ExperimentVariableType.INTEGER:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledIntegerInput
              control={control}
              name={name}
              index={sessionIndex}
              labelText={displayName}
              min={minValue}
              max={maxValue}
            />
          </div>
        );
      case ExperimentVariableType.BOOLEAN:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledBooleanInput control={control} name={name} index={sessionIndex} labelText={displayName} />
          </div>
        );
      default:
        return (
          <div className={styles.inputRow} key={name}>
            <ControlledTextInput control={control} name={name} index={sessionIndex} labelText={displayName} />
          </div>
        );
    }
  });

  return <fieldset>{inputs}</fieldset>;
}
