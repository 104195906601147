import React from 'react';

import { ObjectiveFormatter } from '@app/mappers/experiment/ObjectiveFormatter';
import { ReactComponent as FileSvg } from '@assets/svg/file.svg';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/components/GLDFormSummary/GLDFormSummary.module.scss';

type Props = {
  filenames: string[];
};

export function GLDGroupFilesSummaryCell({ filenames }: Props) {
  return (
    <ul className={styles.filesSummary}>
      {filenames.map((filename, index) => (
        <li key={`${index}_${filename}`}>
          <FileSvg />
          <span>{ObjectiveFormatter.formatFileValueText(filename)}</span>
        </li>
      ))}
    </ul>
  );
}
