import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { Paginate } from '@domain/models/Paginate';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { SortByEvent } from '@ui/types/shared';

const namespace = 'directConfigList';

export const fetchDirectConfigs = ActionFactory.createRequestRoutine<number | undefined, Paginate<IDirectConfig>>(
  'DIRECT_CONFIGS_FETCH',
  namespace
);

export const fetchNextDirectConfigs = ActionFactory.createAction<void>('DIRECT_CONFIGS_NEXT_FETCH', namespace);

export const setFilters = ActionFactory.createAction<DirectConfigFiltersForm>('DIRECT_CONFIG_FILTERS_SET', namespace);
export const setSortBy = ActionFactory.createAction<SortByEvent<keyof IDirectConfig>>(
  'DIRECT_CONFIG_SORT_BY_SET',
  namespace
);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
